<template>
  <div>
    <div
      :class="`bg_col_fff bor_rad_8 ${
        isLandscape
          ? 'pos_fixed l_0 t_0 w_100_vh h_100_vw z_4'
          : 'h_88_vw box_sha_0_0_8_8_black_80_per m_0_16 m_t_16'
      }`"
    >
      <div class="h_100_per dis_flex flex_dir_col p_16">
        <div class="dis_flex ali_it_cen">
          <div class="flex_1 font_16 font_bold">公众号走势</div>

          <!-- 全屏|关闭 -->
          <div class="font_14 col_2396F5" @click="landscapeChange()">
            {{ isLandscape ? '关闭' : '全屏' }}
          </div>

          <!--  -->
        </div>

        <!-- 图表 -->
        <Chart
          v-if="!loading"
          ref="chart"
          class="flex_1 z_0 m_t_8"
          :options="options"
        />
        <div v-else class="flex_1 dis_flex ju_con_cen ali_it_cen">
          <van-loading type="spinner" />
        </div>

        <!--  -->
      </div>

      <!--  -->
    </div>

    <!--  -->
  </div>
</template>

<script>
const moment = window.moment;
import { columnAmount } from '@/utils/tools';

import { brokerDashboardDataUserAnalysisTrendChartPost } from '@/api/index';

export default {
  components: {
    Chart: () => import('@/components/Chart.vue'),
  },
  data() {
    return {
      loading: false,
      options: {},
      isLandscape: false,
      filterForm: {},

      governorList: [],
      brokerProductList: [],

      scrollTop: 0,

      //
    };
  },
  computed: {},
  mounted() {
    this.getTrendChart();

    //
  },
  destroyed() {
    document.body.style = null;
  },
  methods: {
    columnAmount,

    // 横屏 变化
    landscapeChange() {
      this.isLandscape = !this.isLandscape;

      // 横屏
      if (this.isLandscape) {
        this.scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        document.body.style =
          'width: 100vh;height: 100vw;transform: translate(100vw,0) rotate(90deg);transform-origin: 0 0;';
      } else {
        document.body.style = null;
        window.scrollTo({
          top: this.scrollTop,
        });
      }

      this.getTrendChart();

      //
    },

    // 获取 走势图
    async getTrendChart() {
      this.loading = true;

      const data = await brokerDashboardDataUserAnalysisTrendChartPost({
        ...this.filterForm,
      });

      this.loading = false;

      this.options = {
        legend: {
          top: '8px',
        },
        grid: {
          left: '1%',
          top: '28%',
          right: '1%',
          bottom: '4%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          confine: true,
          formatter: (e) => {
            return [
              `<b>${e[0].name}</b>`,

              ...e.map((item) => {
                const i = item.dataIndex;

                let count = 0;
                switch (item.seriesName) {
                  case '新增用户':
                    count = data.map((it) => it.newUser)[i];
                    break;
                  case '取关用户':
                    count = data.map((it) => it.cancelUser)[i];
                    break;
                  case '净增长用户':
                    count = data.map((it) => it.increasedUser1)[i];
                    break;
                  case '累计用户':
                    count = data.map((it) => it.cumulateUser)[i];
                    break;
                }

                return `${item.marker}${item.seriesName} 数量：${columnAmount(
                  count,
                  { fixed: 0 }
                )}`;
              }),

              //
            ].join('<br />');
          },
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: this.isLandscape ? 14 : 10,
            rotate: 70,
          },
          data: data.map((it) => moment(it.refDate).format('YYYY-MM-DD')),
        },
        yAxis: {
          type: 'value',
          minInterval: 1,
          boundaryGap: [0, 0.01],
        },
        series: [
          {
            name: '新增用户',
            type: 'line',
            itemStyle: {
              color: '#2396F5',
            },
            data: data.map((it) => it.newUser),
          },
          {
            name: '取关用户',
            type: 'line',
            itemStyle: {
              color: '#5AD8A6',
            },
            data: data.map((it) => it.cancelUser),
          },
          {
            name: '净增长用户',
            type: 'line',
            itemStyle: {
              color: '#E6A23C',
            },
            data: data.map((it) => it.increasedUser1),
          },
          {
            name: '累计用户',
            type: 'line',
            itemStyle: {
              color: '#ff0000',
            },
            data: data.map((it) => it.cumulateUser),
          },
        ],
      };

      //
    },

    //
  },
};
</script>
